.user-loan-confirm-page-section{
    padding: 5vw 0;
}
.user-loan-confirm-page-section h4{
    font-size: 5vw;
    text-align: center;
    padding: 10px 0 20px 0;
}
.user-loan-confirm-page-section h5{
    font-size: 4vw;
    text-align: center;
    color: #fff;
}
.user-loan-confirm-page-section p{
    font-size: 3vw;
}
.user-loan-details-area .card{
    background-color: #13092f;
}

.user-details-card {
    padding-bottom: 10px;
}
.user-loan-details-area ul{
    padding-top: 20px;
}
.user-details-card span:nth-child(1){
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    padding-right: 10px;
}
.user-details-card span:nth-child(2){
    font-size: 14px;
    font-weight: 400;
    color: #bab8b8;
}