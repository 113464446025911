.user-header-area{
    padding: 10px 0;
}
.user-language{
    width: 40px;
}

.user-logo img{
    width: 37px;
    height: 37px;
}

