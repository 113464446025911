.orders-area {
  padding: 20px 0 90px 0;
}

.orders-menu-area h5 {
  font-size: 3vw;
  font-weight: 500;
  color: #fff;
  padding: 1.5vw 3vw;
  background-color: #1d113d;
  border-radius: 3.5vw;
}
.orders-menu-area .active {
  background-color: #0166fc;
  border-radius: 3.5vw;
}

.orders-profit-loss {
  padding: 10px 0;
  text-align: center;
}

.orders-profit-loss h5 {
  font-size: 4vw;
  font-weight: 500;
  line-height: 12vw;
  color: #fff;
}

.orders-profit-loss h3 {
  font-size: 8.53333vw;
  font-weight: 700;
  line-height: 8.53333vw;
  color: #0166fc;
}

.user-orders-balance-area{
    padding-top: 20px;
    padding-bottom: 10px;
}

.user-orders-balance-area p{
    font-size: 3.2vw ;
   line-height:  3.2vw;
   font-weight: 400;
   color: #cbcbcb;
}
.user-orders-balance-area span{
    font-size: 4.26667vw;
   line-height: 4.26667vw;
   font-weight: 600;
   color: #fff;
}
.orders-history{
    padding: 10px 0;
}
.orders-history-item{
    padding-bottom: 15px;
}

.orders-history-title h5{
    font-size: 4vw;
    font-weight: 400;
    color: #fff;
    padding-bottom: 10px;
}

.orders-item-details p{
    font-size: 3.5vw;
    font-weight: 400;
    color: #fff;
}
.orders-item-details span{
    font-size: 3vw;
    font-weight: 400;
    color: #cbcbcb;
    display: block;
}

.orders-item-up-value h6{
    border: 1px solid #0166fc;
    border-radius: 10px;
    font-size: 3.5vw;
    display: flex;
    color: #fff;
    font-weight: 300;

}

.orders-item-up-value h6 p{
    padding: 0.5vw 2.13333vw ;
    background-color: #0166fc;
    border-radius:10px 0 0 10px;
}

.orders-item-up-value h6 span{
    padding: 0.5vw 2.13333vw;
}
.orders-history-item h5{
    color: #0166fc;
    font-size: 6.4vw ;
    padding-top: 10px
}

.orders-item-dwon-value h6{
    border: 1px solid #f23c48;
    border-radius: 10px;
    font-size: 3.5vw;
    display: flex;
    color: #fff;
    font-weight: 300;

}

.orders-item-dwon-value h6 p{
    padding: 0.5vw 2.13333vw ;
    background-color: #f23c48;
    border-radius:10px 0 0 10px;
}

.orders-item-dwon-value  h6 span{
    padding: 0.5vw 2.13333vw;
}


.text-danger-1{
    color: #f23c48 !important;
}

.user-trade-position-modal{
    height: 180vw !important;
}
.user-trade-position-modal .user-history-details-area{
    height: 175vw !important;
}