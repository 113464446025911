.markets-area{
    padding-bottom: 90px;
}
.markets-menu {
  padding: 10px 0;
}
.markets-menu h5 {
  font-size: 14px;
  font-weight: 500;
  background-color: #1d113d;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  text-align: center;
}
.markets-menu .active {
  background-color: #0166fc;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
}
.markets-item{
    padding: 10px 0;
}
.markets-item-name{
    display: flex;
    align-items: center;
}
.markets-item-name img{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
}
.markets-item-name h6{
   font-size: 16px;
   font-weight: 500;
   color: #cbcbcb;
}

.markets-value{
    text-align: center;
}
.markets-value p{
   font-size: 15px;
   font-weight: 400;
   color: #cbcbcb;
   padding-bottom: 2px;
}
.markets-value span{
   font-size: 14px;
   font-weight: 400;
   color: #0166fc;
   padding-bottom: 2px;
}
.markets-value span svg{
   color: #0166fc;
}

@media (min-width: 350px) and (max-width: 400px) {
    .markets-menu h5 {
        font-size: 12px;
    }
}
@media (min-width: 250px) and (max-width: 349.1px) {
    .markets-menu > div{
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5px;
        justify-content:center ;
    }
    .markets-menu h5 {
        font-size: 10px;
    }
}
@media (min-width: 0px) and (max-width: 249.1px) {
    .markets-menu > div{
        display: grid !important;
        grid-template-columns: 1fr 1fr ;
        gap: 5px;
    }
  
}

.closed-market-btn{
    font-size: 2vw;
    color: #cbcbcb;
    --tw-bg-opacity: 1;
    background-color: #1d113d;
    padding: 1vw 2vw;
    border-radius: 5vw;
}