.cFGboa {
  position: relative;
  z-index: 50;
  --tw-bg-opacity: 1;
  background-color: rgb(11 6 25 / var(--tw-bg-opacity));
}

.bjbtbM {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 40;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100vw;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: rgba(11, 6, 25, 0.9);
}

.iitcUi {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 40;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100vw;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: transparent;
}

.kJIlUY {
  display: flex;
  cursor: pointer;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.gdxWox {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  white-space: nowrap;
  border-width: 0px;
}

@media (min-width: 640px) {
  .iitSla {
    height: 2.5rem;
  }
}

.ighICX {
  display: flex;
  flex: 1 1 0%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .idhwIs {
    display: flex;
  }
}

.idhwIs {
  position: fixed;
  z-index: 40;
  /* display: none; */
  border-radius: 9999px;
  padding: 1rem;
}

.jyIJUV {
  position: relative;
}

@media (min-width: 1024px) {
  .epJUan {
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
}

.epJUan {
  display: inline-flex;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border-radius: 0.375rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(172 172 172 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: button;
  background-color: transparent;
  background-image: none;
}

button,
[role="button"] {
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0px;
  padding: 0px;
}

.kZVkLy {
  --tw-text-opacity: 1;
  color: rgb(172 172 172 / var(--tw-text-opacity));
}

.hvYHcu {
  margin-left: 0.5rem;
  height: 1.25rem;
  width: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.idhwIs > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.bjtaVo {
  margin: -0.75rem;
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  border-radius: 0.5rem;
  padding: 0.75rem;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(172 172 172 / var(--tw-text-opacity));
}

.idhwIs > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.bjtaVo {
  margin: -0.75rem;
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  border-radius: 0.5rem;
  padding: 0.75rem;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(172 172 172 / var(--tw-text-opacity));
}

.cimMsP {
  position: relative;
}

.bjtaVo:hover {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.kZVkLy:hover {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.Navbar___StyledChevronDownIcon-sc-19dqj0b-12:hover {
  rotate: 180deg;
  transition: 0.5s;
}
.Navbar___StyledSpan-sc-19dqj0b-11:hover + svg {
  rotate: 180deg;
  transition: 0.5s;
}
.Navbar___StyledChevronDownIcon-sc-19dqj0b-12 {
  transition: 0.5s;
}

@media (min-width: 1024px) {
  .jsPuOT {
    display: flex;
  }
}

.jsPuOT {
  position: relative;
  /* display: none; */
  width: 150px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.iWTuSI {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 9999px;
  border-width: 1px;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(15 9 32 / var(--tw-text-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

@media (min-width: 1024px) {
  .bPyWNO {
    left: 50%;
    margin-left: 0px;
    max-width: 28rem;
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.hmyHJB {
  /* overflow: hidden; */

  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 0.05;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

@media (min-width: 1024px) {
  .iDBTFF {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
}

@media (min-width: 640px) {
  .iDBTFF {
    gap: 2rem;
    padding: 2rem;
  }
}

.iDBTFF {
  position: relative;
  display: grid;
  --tw-bg-opacity: 1;
  background-color: rgb(22 24 29 / var(--tw-bg-opacity));
  padding: 1.5rem 1.25rem;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.5rem;
  padding: 2rem;
}

.bxUEjX {
  margin: -0.75rem;
  display: flex;
  align-items: center;
  /* align-items: flex-start; */
  border-radius: 0.5rem;
  padding: 0.75rem;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.fZyHgL {
  display: flex;
  flex-shrink: 0;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 0.75rem;
  padding: 1px;
}

@media (min-width: 1024px) {
  .hmtesw {
    margin-left: 0.75rem;
  }
}

.bDNURc {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.galZQZ {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.bxUEjX:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(11 6 25 / var(--tw-bg-opacity));
}
.Navbar__CommunityLinkWrapper-sc-19dqj0b-4:hover
  + .Navbar___StyledP-sc-19dqj0b-18 {
  text-decoration: underline;
  color: red;
}

.bPyWNO {
  position: absolute;
  z-index: 10;
  /* margin-left: -21px; */
  margin-top: 0.75rem;
  width: 100vw;
  max-width: 28rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.enterTo {
  --tw-translate-y: 0px;
}

.submenu-transition {
  transition: 5s;
}

.Navbar__CommunityItem-sc-19dqj0b-7 svg {
  font-size: 30px;
  color: #fe9a02;
}
.mobile-header-menu {
  display: none;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .mobile-header-menu {
    display: block;
  }
  .desktop-menu {
    display: none;
  }
  .jsPuOT {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 185px;
  }
  .mobile-header-menu svg {
    font-size: 30px;
    color: #fff;
  }
  .Navbar___StyledImg-sc-19dqj0b-16 {
    height: 35px;
    width: auto;
  }

  /* /////////////////////////////////// */

  @media (min-width: 768px) {
    .inXrJ {
      width: 50%;
      padding: 1.25rem;
    }
  }

  .inXrJ {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 72px;
    z-index: 50;
    margin: auto;
    width: 90%;
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(11 6 25 / var(--tw-bg-opacity));
    padding: 20px 30px;
  }

  .dCckjJ {
    position: relative;
  }

  .epJUan {
    display: inline-flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-radius: 0.375rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(172 172 172 / var(--tw-text-opacity));
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, opacity, box-shadow, transform,
      filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
  }

  .kZVkLy {
    --tw-text-opacity: 1;
    color: rgb(172 172 172 / var(--tw-text-opacity));
  }

  .hvYHcu {
    margin-left: 0.5rem;
    height: 1.25rem;
    width: 1.25rem;
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }

  .inXrJ > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }

  .eTkSck {
    display: block;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, opacity, box-shadow, transform,
      filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    appearance: button;
    background-color: transparent;
    background-image: none;
    border: navajowhite;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0px;
    padding: 0px;
  }

  .epJUan:focus {
    outline: transparent solid 2px;
    outline-offset: 2px;
  }

  .epJUan:hover {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
  }

  .mobile-sub-menu svg {
    padding-right: 10px;
  }

  .mobile-sub-menu .group {
    padding-bottom: 20px;
  }
  .bPyWNO {
    width: 100%;

  }
}
