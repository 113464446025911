.news-section-title {
  padding: 5vw 0;
  text-align: center;
  background-color: #1d113d;
}
.news-section-title h5 {
  font-size: 6vw;
  color: #fff;
}
.main-news {
  padding: 5vw 0;
}
.news-tem {
  display: flex;
  justify-content: end;
  align-items: center;
}
.news-tem p {
  font-size: 3vw;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  margin-left: 3vw;
}
.news-tem p svg {
  font-size: 3vw;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  padding-right: .5vw;
}

.news-tem .active {
  color: #0166fc;
}
.news-tem .active svg {
  color: #0166fc;
}

.news-lists-item {
  display: flex;
}
.news-lists-items {
  padding-top: 40px;
}
.news-lists-items .news-lists-item {
  /* box-shadow: #1d113d  5px 5px, #1d113d  10px 10px; */
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  margin-bottom: 30px;
  border-radius: 10px;
}
.news-lists-items .news-lists-item img {
  width: 130px;
  height: auto;
  padding-right: 1vw;
  border-radius: 10px 0 0 10px;
}
.news-lists-items .news-lists-item .news-content {
  padding: 1.5vw 0;
}
.news-lists-items .news-lists-item h4 {
  font-size: 3vw;
  color: #fff;
  font-weight: 500;
  line-height: 3.5vw;
  width: 95%;
  padding-bottom: .5vw;
}
.news-lists-items .news-lists-item p {
  font-size: 1.8vw;
  color: #b4b3b3;
  font-weight: 400;
  line-height: 2vw;
  width: 95%;
  padding-bottom: .5vw;
}
.news-lists-items .news-lists-item span {
  font-size: 1.8vw;
  color: #b4b3b3;
  display: block;
}
.news-lists-items .news-lists-item a {
  font-size: 1.5vw;
  color: #b4b3b3;
  background-color: #1d113d;
  padding: 1vw 2vw;
  border-radius: 1vw;
  font-weight: 600;
}
.news-lists-items .news-lists-item a:hover {
  color: #fff;
  background-color: #0166fc;
}

.news-grids-area{
  padding: 4vw 0;
}

.news-grids-area .hover {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  border-radius: 10px;
}

.news-grids-area .module {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s linear 0s;
  overflow: hidden;
  position: relative;
  margin-bottom: 7vw;
}
.news-grids-area .module:hover {
  box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
}
.news-grids-area .thumbnail {
  position: relative;
  overflow: hidden;
  background: black;
}
.news-grids-area .thumbnail img {
  width: 100%;
  transition: all 0.3s;
}
.news-grids-area .module:hover .thumbnail img {
  transform: scale(1.1);
  opacity: 0.6;
}

.news-grids-area .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #0166fc;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  padding: 3.5vw 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.5vw;
  text-transform: uppercase;
}

.news-grids-area .content {
  background-color: #1d113d;
  padding: 3vw;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}

.news-grids-area .title {
  margin: 0;
  padding: 0 0 .5vw;
  color: #fff;
  font-size: 4vw;
  font-weight: 600;
  line-height: 4.5vw;
}
.news-grids-area .description {
  color: #989797;
  font-size: 14px;
  line-height: 1.8em;
  height: 0;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  overflow: hidden;
}
.news-grids-area .module:hover .description {
  height: 100px;
}

.news-grids-area span {
  font-size: 3vw;
  color: #b4b3b3;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 70%;
  padding-top: 2vw;
}

.news-grids-area .col-4 {
  margin: 1.5rem 0;
}

/* news details  */

.news-details-section{
  padding-bottom: 90px;
}

.news-details-title h5 {
  font-size: 5vw;
  line-height: 7vw;
  color: #fff;
  padding: 5vw 0;
}
.news-details-image img {
  width: 100%;
}
.news-details-image span {
  font-size: 3vw;
  color: #b4b3b3;
  display: block;
  width: 100%;
  padding-bottom: 2vw;
}
.news-details-dis h5 {
  font-size: 3.5vw;
  line-height: 4.5vw;
  color: #969393;
}
.news-details {
  padding: 3vw 0;
  color: #fff;
}

.news-details p{
  color: #fff !important;
}

.news-details li{
  color: #fff !important;
}
