.mining-area{
    padding-top: 5vw;
    padding-bottom: 5vw;
}
.mining-area .card-custom {
    background: #13092F;
    color: white;
    border-radius: 10px;
    border: 2px solid #13092F;
    padding: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.mining-area .badge-custom2 {
    background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
    border-radius: 10px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
    transform: rotate(-35deg);
}

.mining-area .badge-custom {
    background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
    border-radius: 10px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 20px;
    position: absolute;
    top: -15px;
    right: -15px;
    transform: rotate(-35deg);
}

.mining-area .text-box {
    background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
    color: white;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    margin-top: 30px;
    width: 200px;
    padding: 5px 10px;
    border-radius: 10px;
    right: -45px;
    justify-content: end;
}


.mining-area .investment-info {
    font-size: 16px;
    padding: 15px 0;
}

.mining-area .investment-info p{
    padding-bottom: 5px;
}

.mining-area .countdown {
    border-radius: 6px;
    padding: 5px;
}

.mining-area .countdown-timer {
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.mining-area .countdown-timer div {
    margin: 0 5px;
    padding: 2px 5px;
    border-radius: 6px;
}

.mining-area .form-control {
    border: 1px solid #596280;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
    color: #bcd0f7;
    padding: .75rem 1rem;
}
.mining-area .form-control::placeholder {
   color: #fff;
}

.mining-area .btn-custom {
    background: linear-gradient(to right, #8363F9, #41B8F9);
    border: none;
    border-radius: 25px;
    color: white;
    font-size: 16px;
    margin-top: 20px;
    padding: 10px 20px;
    width: fit-content;
    align-self: flex-center;
}


/* history  */

.mining-area .card {
    background: #014670;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem;
}

/* .mining-area .text-primary {
    color:  rgb(255, 211, 2) !important;
} */
/* .mining-area .table-responsive{
    overflow-x: scroll;
}
.mining-area .table-responsive table{
    --bs-table-bg: #0075BB !important;
    background-color: #0075BB !important;
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    overflow: hidden;
}
.mining-area .table>:not(caption)>*>*{
    color: #bcd0f7 !important;
}
.mining-area .table-responsive td{
    color: #fff !important;
} */


/* ///// */







/* Shutter In Horizontal */
.hvr-shutter-in-horizontal {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    background: linear-gradient(45deg, #0ecbff, #4663ff) !important;
    color: #fff;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    padding: 8px 60px 11px 20px;
    border-radius: 20px;
    position: relative;
    border: 1px solid #2492f6;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 10px;
  }
  .hvr-shutter-in-horizontal:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #241154;
    background: linear-gradient(45deg, #6718ce, #2970f9) !important;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    border-radius: 20px;
  }
  .hvr-shutter-in-horizontal:hover, .hvr-shutter-in-horizontal:focus, .hvr-shutter-in-horizontal:active {
  color: white;
  }
  .hvr-shutter-in-horizontal:hover:before, .hvr-shutter-in-horizontal:focus:before, .hvr-shutter-in-horizontal:active:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  }
  
  .hvr-shutter-in-horizontal {
    font-size: 13px;
    font-weight: 500;
    padding: 7px  40px;
    text-align: center;
  }



  
/******=======  CALCI PART START  =======******/
.calci_bg{
    padding: 50px 0px 0px;
    margin: 0px;
  }
  .calci_bg .container{
    position: relative;
  }
  .calci_bg .calci{
  
  
  }
  .calci_bg .calci1{
    background: #050618;
    padding: 15px 20px;
    border: 1px solid #03307b;
    border-radius: 15px;
  }
  .calci_bg li{
    display: inline-block;
    width: 49%;
  }
  .calci_bg .cal_text{
    margin: 0px 5px;
  }
  .calci_bg .cal_text h3{
    font-size: 14px;
    font-weight: 500;
    color: #b2b3ba;
    padding: 0px;
    margin: 0px;
  }
  .calci_bg .cal_text h3 span{
    display: block;
    margin: 10px 0px 0px 0px;
  }
  .calci_bg .cal_text select{
    width: 100%;
    padding: 8px 10px;
    border-radius: 5px;
    background: #0c1633;
    border: 1px solid #1b459c;
    outline: none;
    font-size: 15px;
    font-weight: 600;
    color: #1aa7ef;
    margin: 10px 0px 0px 0px;
  }
  .calci_bg .cal_text input{
    width: 100%;
    padding: 11px 10px;
    border-radius: 5px;
    background: #0c1633;
    border: 1px solid #1b459c;
    outline: none;
    font-size: 15px;
    font-weight: 600;
    color: #1aa7ef;
    margin: 10px 0px 0px 0px;
  }
  .calci_bg .cal_text span{
    width: 100%;
    padding: 11px 10px;
    border-radius: 2px;
    background: #0e1128;
    border: none;
    outline: none;
    font-size: 15px;
    font-weight: 600;
    color: #1aa7ef;
  }
  .calci_bg .cal_text .cl1{
    color: #f98305;
  
  }
  
  .calci_bg .calci1 {
    margin: 20px 0px 0px;
  }
  
  /******=======  CALCI PART END  =======******/
  