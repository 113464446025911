.user-menu {
  padding-bottom: 90px;
}
.user-menu .user-details {
  padding-top: 6.93333vw;
  width: 100%;
  height: 49.6vw;
  background-color: #0166fc;
}
.user-menu .user-details h5 {
  color: rgb(255 255 255 / 1);
  font-weight: 700;
  font-size: 5.33333vw;
}
.user-menu .user-details p {
  --tw-text-opacity: 1;
  color: rgb(183 222 255 / 1);
  font-size: 3.2vw;
  margin-top: 0.53333vw;
}
.user-menu .user-details h4 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / 1);
  font-weight: 700;
  font-size: 5.33333vw;
}
.user-menu .user-details svg {
  color: rgb(255, 255, 255);
  font-size: 1.4rem;
  padding: 1.6vw;
}

.user-menu .user-details span {
  --tw-text-opacity: 1;
  color: rgb(183 222 255 / 1);
  font-size: 3.2vw;
  display: grid;
}

.user-menu-deposit-btn {
  border-radius: 4.26667vw;
  --tw-bg-opacity: 1;
  background-color: rgb(1 102 252 / 1);
}

.user-menu-deposit-btn svg {
  width: 9.06667vw;
  height: 9.06667vw;
  margin-left: 2.13333vw;
  margin-right: 2.13333vw;
}

.user-menu-deposit-btn p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / 1);
  font-weight: 600;
  font-size: 3.73333vw;
  line-height: 3.73333vw;
  padding-bottom: 1.5vw;
}

.user-menu-deposit-btn span {
  opacity: 0.7;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / 1);
  font-size: 3.2vw;
  line-height: 3.2vw;
}

.user-menu-deposit-btn div {
  display: grid;
}

.user-menu-Withdrawal-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(242 60 72 / 1);
}

.user-menu-items {
  padding-top: 30px;
}
.user-menu-item{
    margin-top: 2.66667vw !important;
    padding-top: 2.13333vw !important;
    padding-bottom: 2.13333vw !important;
}
.user-menu-item > div svg {
  font-size: 3.73333vw;
  padding: 1.6vw;
  color: rgb(1 102 252 / 1);
  background-color: #1d113d;
  border-radius: 1.66667vw;
}
.user-menu-item > div p {
  --tw-text-opacity: 1;
  color: #7000ff;
  font-weight: 600;
  font-size: 3.73333vw;
  padding-left: 2.66667vw;
}
.user-menu-item svg {
    color: #280952;
  font-size: 3.73333vw;
}
