.user-kyc-data-item{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.user-kyc-data-item h5{
    font-size: 4vw;
    margin-right: 15px;
    color: #fff;
}
.user-kyc-data-item p{
    font-size: 3vw;
    color: #cbcbcb;
}
.user-kyc-data-item a{
    color: #0166fc;
}
.user-kyc-data-status{
    font-size: 2vw !important;
    display: block;
}