@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(229, 231, 235);
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: var(--tw-empty, /*!*/ /*!*/);
  --tw-pan-y: var(--tw-empty, /*!*/ /*!*/);
  --tw-pinch-zoom: var(--tw-empty, /*!*/ /*!*/);
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: var(--tw-empty, /*!*/ /*!*/);
  --tw-gradient-via-position: var(--tw-empty, /*!*/ /*!*/);
  --tw-gradient-to-position: var(--tw-empty, /*!*/ /*!*/);
  --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-blur: var(--tw-empty, /*!*/ /*!*/);
  --tw-brightness: var(--tw-empty, /*!*/ /*!*/);
  --tw-contrast: var(--tw-empty, /*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty, /*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty, /*!*/ /*!*/);
  --tw-invert: var(--tw-empty, /*!*/ /*!*/);
  --tw-saturate: var(--tw-empty, /*!*/ /*!*/);
  --tw-sepia: var(--tw-empty, /*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-blur: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty, /*!*/ /*!*/);
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: Inter;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
  animation: 6s ease 0s infinite alternate none running gradient;
  margin: 0px;
}

a {
  text-decoration: none;
  color: #fff;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

p {
  margin: 0;
  padding: 0;
}

dl,
ol,
ul {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
}

.main-area {
  overflow: hidden;
}

img {
  height: auto;
  max-width: 100%;
}
svg {
  color: #fff;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

p {
  margin: 0;
}
/*removes original number*/
.custom-lists ol {
  counter-reset: item;
  list-style-type: none;
}

.custom-lists ol li {
  display: block;
}

.custom-lists ol li:before {
  content: counter(item) ".";
  counter-increment: item;
}

/*large-number font*/
.custom-lists ol li:before {
  position: absolute;
  margin-left: -70px;
  font: bold italic 45px/1.5 Helvetica, Verdana, sans-serif;
  color: #ccc;
  top: 0;
}

/*Heading font*/
.custom-lists ol li {
  font: 18px Open Sans, sans-serif;
  line-height: 32px;
  padding-left: 60px;
  color: #000;
  position: relative;
}

/*paragraph font*/
.custom-lists li p {
  font: 16px Open Sans, sans-serif;
  line-height: 30px;
  color: #555;
}

/*width and style of list*/
.custom-lists ol {
  text-align: justify;
}

/*generic alignment*/
.custom-lists ol li {
  text-indent: 0 !important;
  padding-left: 20px !important;
  list-style-position: outside;
  margin-left: 20px;
  list-style-type: number;
}

.custom-lists ol li {
  text-align: left;
  text-indent: 0 !important;
  padding-left: 20px !important;
  list-style-position: outside;
  margin-left: 20px;
  padding: 15px 0;
}

.custom-main-section h5 {
  font-size: 30px;
  padding: 28px 0;
}

@media (min-width: 0px) and (max-width: 550px) {
  .custom-lists ol li {
    padding-left: 0px !important;
    margin-left: 0px !important;
    padding: 0 !important;
  }

  .custom-lists ol,
  ul {
    padding-left: 0px !important;
  }

  .custom-lists ol li:before {
    display: none;
  }
}

/* .container{
    --bs-gutter-x: 3.5rem;

} */

.mt-20 {
  margin-top: 5.33333vw !important;
}

.px-10 {
  padding-left: 2.66667vw !important;
  padding-right: 2.66667vw !important;
}

.w-160 {
  width: 42.66667vw !important;
}

.h-52 {
  height: 13.86667vw !important;
}

/* form area  */

.form-control {
  color: #fff;
  background-color: rgb(11, 6, 25);
  border: none;
}

.form-control:focus {
  color: #fff;
  background-color: #1d113d;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.form-select {
  background-color: rgb(11, 6, 25);
  color: #fff;
  border: none;
}

.form-select:focus {
  color: #fff;
  background-color: #1d113d;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

label {
  color: #fff;
}

pre {
  color: #cbcbcb;
}

.user-form-section {
  padding: 20px 0 90px 0;
}

.user-form-section .card {
  --bs-card-bg: #13092f;
}

/* file */

/**/

.input-file {
  position: relative;
  overflow: hidden;
  padding: 0;
  display: block;
  max-width: 100%;
  cursor: pointer;
  display: inline-block;
}

.input-file .input-file-btn {
  white-space: nowrap;
  display: inline-block;
  margin-right: 1em;
  vertical-align: top;
  background-color: rgb(11, 6, 25);
  padding: 1.5vw 5vw;
  border-radius: 1.5vw;
  color: #fff;
  font-size: 2.5vw;
}

.input-file .input-file-icons {
  float: left;
  font-size: 16px;
  line-height: inherit;
  margin-right: 4px;
}

.input-file ins {
  white-space: nowrap;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.input-file:after {
  content: "";
  display: block;
  clear: both;
}

.input-file input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: -100px;
  z-index: -1;
}
.user-submit-btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-submit-btn button {
    background-color: #0166fc;
    padding: 2vw 15vw;
    border-radius: 4vw;
  }

  .user-form-back{
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 4vw;
    padding-left: 2vw;
  }

  .user-form-back h6{
    /* max-width: 60%; */
    margin: 0 auto;
    font-size: 4.26667vw;
    color: #0166FC;
  }
  .user-form-back svg{
    position: absolute;
    top: 5px;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 3.73333vw ;
  }

  .user-form-section .text-danger{
    font-size: 3vw !important;
  }



  /* table  */

  
.user-data-table h4 {
  padding: 20px 0;
}

.user-data-table .card {
  background-color: #1d113d !important;
}
.user-data-table table {
  --bs-table-bg: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
  background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
  padding: 10px 0;
  margin-bottom:0;
}

.user-data-table .table > :not(caption) > * > * {
  color: #fff;
  border-color: #1d113d;
}


/* custom pagination css  */

.mobile-page-number {
  display: none;
}
.custom-pagination .disabled>.page-link, .page-link.disabled {
  color: #8f8d8d;
  pointer-events: none;
  background-color: rgb(11 6 25 / 38%);
  border-color:  rgb(11 6 25 / 38%) ;
}
.custom-pagination .page-link:focus {
  box-shadow: 0 0 0 0.25rem #1d113d;
}
.custom-pagination .page-link{
  border: none;
}
.custom-pagination .page-link {
  color: #fff;
  background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
}



/* user history modal css  */

.user-history-modal-area{
  background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
  border-radius: 20px 20px 0 0;
  padding: 5vw 0;
  height: 120vw !important;
}

.user-history-modal-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}
.user-history-modal-header h5{
  font-size: 4vw;
  color: #fff;
}
.user-history-modal-header svg{
  font-size: 5vw;
  color: #f23c48;
}
.user-history-details-area{
  overflow: scroll;
  height: 110vw;
  padding-bottom: 100px;
}
.user-history-details-area h2{
  font-size: 4vw;
  color: #fff;
  padding-bottom: 15px;
  font-weight: 400;
}

.user-history-modal-area .orders-item-details p{
  display: flex;
  align-items: center;

}
.user-history-modal-area .orders-item-details p span{
  font-size: 3.5vw;
  font-weight: 400;
  color: #0166fc;
  padding-left: 5px;
}

.user-history-items p{
  font-size: 3vw;
  color: #63538e;
  padding-bottom: 5px;
}


.round-20{
  border-radius: 10px;
}

.user-trade-close-btn p{
  cursor: pointer;
}

.order-history-update-btn{
  padding: 2vw 6vw;
  background-color: #0166FC !important;
  color: #fff;
  font-size: 3vw;
  line-height: 1vw;
  font-weight: 400;
  border-radius: 10vw;
}



/* order filter area  */

.order-filter-area{
  padding-bottom: 20px;
   display: flex;
   align-items: center;
   justify-content: end;
 }
 
 .order-filter-area  p{
   padding-right: 2vw;
 }


 .sweetalert2-body{
  background-color: #1d113d;
  width: 80%;
  margin: auto;
}

.sweetalert2-title{
  color: #F27474;
}
.sweetalert2-htmlContainer{
  color: red !important;
}