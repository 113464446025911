.menu-indicator-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  background: 0 0 / 300% 300% no-repeat fixed #0b0619;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20x 0 0;
  font-family: "Poppins", sans-serif;
  z-index: 999;
  /* border-top: 1px solid #1d113d; */
}

.menu-indicator-navigation ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-evenly;
  width: 100%;
  padding: 0;
  align-items: center;
}

.menu-indicator-navigation li {
  position: relative;
  list-style: none;
  z-index: 1;
}

.menu-indicator-navigation li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
  color: #7000ff;
}
.menu-indicator-trade {
  padding: 8px;
  border-radius: 50%;
  background-color: #0166fc;
  margin-top: -40px;
}
.menu-indicator-trade svg {
  background-color: transparent !important;
  color: #fff !important;
}

.menu-indicator-navigation a .icon svg {
  position: relative;
  display: block;
  line-height: 75px;
  height: 18px;
  width: 18px;
  text-align: center;
  transition: 0.5s;
  color: #0166fc;
  background-color: #1d113d;
  padding: 5px;
  border-radius: 5px;
}

.menu-indicator-navigation a .text {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.05em;
  transition: 0.5s;
  padding-top: 5px;
}

.menu-indicator-navigation .list-link {
  color: #1d113d;
}
.menu-indicator-navigation .list-link .active {
  color: #0166fc;
}
.menu-indicator-navigation .list-link .active .icon svg {
  color: #fff;
  background-color: #0166fc;
}
