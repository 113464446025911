.user-trade-page-area{
    padding: 20px 0 90px 0;
}

.user-trade-header-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5vw 0;
}

.user-trade-header-section p{
    font-size: 3.5vw;
    color: #7000ff;
    cursor: pointer;
}
.user-trade-header-section p svg{
    font-size: 3.5vw;
    color: #7000ff;
    cursor: pointer;
}
.user-trade-header-section span{
    font-size: 3.5vw;
    color: #0166fc;
}

.trade-offcanvas-area{
    background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
    animation: 6s ease 0s infinite;
    width: 100% !important;
}
.trade-offcanvas-area .offcanvas-close-btn {
    text-align: end;
    padding: 2vw 0;
}
.trade-offcanvas-area .offcanvas-close-btn svg{
    font-size: 6vw;
    color: red;
}

.trade-market-item{
    overflow: scroll;
    height: 85vh ;
    padding-bottom: 20px;
}




/*------------------------- form area css  ----------------------- */


.user-trade-page-area .user-trade-page {
    padding: 10vw 0;
}
.user-trade-page-area .user-pending-order-btn-area {
    text-align: center;
    margin: auto;
    background-color: #13092f;
    border-radius: 4.26667vw ;
    display: flex ;
    overflow: hidden ;
}

.user-trade-page-area .user-pending-order-btn-area > div {
    flex: 1 1 0% ;
    padding-top: 1.6vw ;
    padding-bottom: 1.6vw ;
    font-size: 4vw;
    color: #fff;
}
.user-trade-page-area .user-pending-order-btn-area  .active{
    background-color: #0166FC;
}

.user-trade-page-area .user-pending-order-btn-area button{
    background-color: #0166FC;
}

.user-trade-page-area .min-w-324 {
    min-width: 324px !important;
}
.user-trade-page-area .w-324 {
    width: 324px !important;
}
.user-trade-page-area .form-control {
    background-color: rgb(19 12 41) ;
}


.user-trade-page-area .text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / 1 ) !important;
}
.user-trade-page-area .text-center {
    text-align: center !important;
}
.user-trade-page-area .py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.user-trade-page-area .px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.user-trade-page-area .bg-primary {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(1 102 252 / 1 ) !important;
}
.user-trade-page-area .rounded-10 {
    border-radius: 10px !important;
}
.user-trade-page-area .mb-18 {
    margin-bottom: 18px !important;
}


.user-trade-page-area .text-14 {
    font-size: 14px !important;
}
.user-trade-page-area .py-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.user-trade-page-area .text-14 {
    font-size: 14px !important;
    color: #fff;
}

.user-trade-page-area .font-bold {
    font-weight: 700 !important;
}
.user-trade-page-area .text-lg {
    font-size: 28px !important;
}


.user-trade-page-area .box-shadow {
    box-shadow: 0 0 10px #a1a1a11f;
}
.user-trade-page-area .p-20 {
    padding: 20px !important;
}
.user-trade-page-area .bg-white {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / 1 ) !important;
}
.user-trade-page-area .rounded-10 {
    border-radius: 10px !important;
}
.user-trade-page-area .box-border {
    box-sizing: border-box !important;
}

.user-trade-page-area .text-kline-up {
    --tw-text-opacity: 1 !important;
    color: rgb(1 102 252 / 1 ) !important;
}
.user-trade-page-area .text-xs {
    font-size: 20px !important;
}

.user-trade-page-area .mt-10 {
    margin-top: 10px !important;
}
.user-trade-page-area .justify-between {
    justify-content: space-between !important;
}
.user-trade-page-area .items-center {
    align-items: center !important;
}
.user-trade-page-area .flex {
    display: flex !important;
}
.user-trade-page-area .mt-8 {
    margin-top: 8px !important;
    color: #fff;
}



.user-trade-page-area .buy-btn{
    padding: 4vw 17vw;
    background-color: #0166FC;
    color: #fff;
    font-size: 4vw;
    line-height: 1vw;
    font-weight: 500;
    border-radius: 10vw;

}

.user-trade-page-area .buy-btn:disabled{
    background-color: #3582f6;
    cursor: not-allowed;

}


.user-trade-page-area .sell-btn{
    padding: 4vw 17vw;
    background-color: #F23C48;
    color: #fff;
    font-size: 4vw;
    line-height: 1vw;
    font-weight: 500;
    border-radius: 10vw;
}

.user-trade-page-area .sell-btn:disabled{
    background-color: #f56b74;
    cursor: not-allowed;

}

.user-trade-page-area .el-select input::placeholder{
    color: #a5a0a0;
}



.trade-page-fs-4{
    font-size: 4vw;
}



/* plus minus */
.user-trade-page-area .trade-quantity {
    display: flex;
    align-items: center;
  }
  .user-trade-page-area .trade-quantity  .btn {
    cursor: pointer;
    background-color: #13092f;
    outline: none;
    color: #fff;
    border-radius: 0;
  }
  .user-trade-page-area .trade-quantity  input {
   border-radius: 0;
  }
  .user-trade-page-area  .trade-dis-btn{
    display: flex;
    align-items: center;

    justify-content: space-between;
  }
  .user-trade-page-area  .form-switch input{
    cursor: pointer;
  }

  .user-trade-page-area  .form-control:disabled {
    background-color: #201247;
    opacity: 1;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    border-color: none !important;
    border: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.user-trade-page-area input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}


.candle-time-framer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.candle-time-framer p{
    background-color: #13092F;
    color: #fff;
    padding: 1vw 2.5vw;
    border-radius: 5px;
    cursor: pointer;
    font-size: 3vw;
}
.candle-time-framer .active{
    background-color: rgb(1 102 252 / 1 );
}

.user-live-data-show{
    padding: 1vw 0;
}



.user-live-data{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
}
.user-live-data p{
  font-size: 2vw;
  color: #b2b3ba;
}

.tv-lightweight-charts a {
    display: none !important;
}
.tv-lightweight-charts  {
    width: 100% !important;
}
.tv-lightweight-charts  table{
    width: 100% !important;
}