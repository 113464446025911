.assets-menu-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99%;
  overflow: scroll;
  margin-bottom: 20px;
}

.assets-menu-area p {
  font-size: 4vw;
  color: #a5a5a5;
  padding: 10px 20px;
}

.assets-menu-area .active {
  color: #fff;
}
.assets-menu-area .active::after {
  content: "";
  display: block;
  width: 35px;
  background-color: #0166fc;
  height: 2px;
  margin: 1px auto 0 auto;
}

.assets-user-balance-area p {
  font-size: 3vw;
  color: #dcdcdc;
}
.assets-user-balance-area p svg.active {
  color: #f30909;
}

.assets-user-balance-area span {
  font-size: 5vw;
  color: #fff;
  display: block;
  padding: 5px 0 2px 0;
}
.assets-user-balance-area small {
  font-size: 2.5vw;
  color: #fff;
  display: block;
}
.user-credit-score p {
  font-size: 3vw;
  color: #a5a5a5;
}

.assets-btn-area {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.assets-btn-area a {
  font-size: 3vw;
  padding: 5px 20px;
  border-radius: 10px;
  background-color: rgb(11, 6, 25);
  color: #fff;
}

.user-assets-overview {
  padding-top: 50px;
}

.user-assets-overview-items {
  padding-bottom: 30px;
}

.user-assets-overview-items::after {
  content: "";
  display: block;
  width: 100%;
  background-color: #13092f;
  height: 1px;
  margin-top: 5px;
}

 .user-assets-overview-items p {
  font-size: 3.5vw;
  color: #fff;
  padding-bottom: 1px;
  line-height: 0;
}
 .user-assets-overview-items span {
  font-size: 3vw;
  color: #a5a5a5;
}

.user-asset-currency-area {
  padding-top: 30px;
}

.user-asset-currency-top-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-asset-currency-top-area #submitsearch {
  border: 1px solid rgb(138, 134, 134);
  margin-left: -82px;
  padding: 5px;
  border-radius: 19px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 8px;
  padding-top: 4px;
  display: none;
  box-shadow: 0 0 1px black;
  margin-right: 110px;
}

.user-asset-currency-top-area #searchInput {
  width: 100%;
  border: 1px solid #000;
  border-radius: 10px;
  font-size: 3vw;
  background-color: #13092f;
  background-image: url("https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-24.png");
  background-position: 5px 6px;
  background-repeat: no-repeat;
  padding: 2px 10px 2px 30px;
  -webkit-transition: width 0.8s ease-in-out;
  transition: width 0.8s ease-in-out;
  outline: none;
  opacity: 0.9;
  background-size: 3vw;
  color: #fff;
}

.user-asset-currency-top-area p {
  font-size: 2.5vw;
  color: #dcdcdc;
  cursor: pointer;
}

.user-asset-currency-items-area {
  padding-top: 30px;
}

.user-assets-overview-items .image-area {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.user-assets-overview-items .image-area img{
 width: 30px;
 height: 30px;
 border-radius: 50%;
 margin-right: 10px;
}
.user-assets-overview-items .image-area small{
font-size: 3.5vw;
color: #fff;
}
